<template>
    <div class="FolderSelection">
        <div @click="addSubFolder()">新建子文件夹
            <i class="iconfont  icon-page-right"></i>

        </div>
        <div @click="addPeerFolder()">新建同级文件夹
            <i class="iconfont  icon-page-right"></i>
        </div>
    </div>

</template>
<script>
import titleMixin from '@/mixin/title'
export default {
    name: 'FolderSelection',
    title: '新建文件夹',
    mixins: [titleMixin],
    data() {
        return {
            targetBoxId: -8,
            boxPath: '',
            selectboxId: ''
        }
    },
    computed: {

    },
    created() {
    },
    mounted() {
        this.pageInit()
    },
    methods: {
        addPeerFolder() { // 同级文件夹
            if (this.boxPath == this.selectboxId) {
                this.targetBoxId = -2
            } else {
                let arry = this.boxPath.split('/')
                this.targetBoxId = arry[arry.length - 2]
            }
            this.$router.push({ name: 'folderAdd', params: { targetBoxId: this.targetBoxId, boxPath: this.boxPath, selectboxId: this.selectboxId, type: 'addPeer' } })
        },
        addSubFolder() { // 子文件夹
            let arry = this.boxPath.split('/')
            this.targetBoxId = arry[arry.length - 1]
            this.$router.push({ name: 'folderAdd', params: { targetBoxId: this.targetBoxId, boxPath: this.boxPath, selectboxId: this.selectboxId, type: 'addSub' } })
        },
        pageInit() {
            this.Global.utils.rightMenu.clearMenu()
            this.boxPath = this.$route.params.boxPath
            this.selectboxId = this.$route.params.boxId
        }

    },
    watch: {
        $route(to, old) {
            if (this.$route.path == '/mail/folderSelection' && old.path == '/mail/home') {
                this.pageInit()
            } else if (this.$route.path == '/mail/mailList') {
                this.Global.utils.rightMenu.clearMenu()
                this.boxPath = old.$route.params.boxPath
                this.selectboxId = old.$route.params.selectboxId
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
